import React from 'react';
import Layout from '../../../components/layout';
import RegistrationFrom from '../../../components/registration-form/registration-from';
import SEO from '../../../components/seo';

export default function RegisterDubaiChamber(props) {
  return (
    <Layout>
      <SEO lang='en' title='#GMIS2022 - Dubai Chamber' />
      <RegistrationFrom
        imageName='dubai-chamber-expo'
        imageAlt='dubai-chamber-expo'
        source='Dubai Chamber'
        page='dubai-chamber'
      />
    </Layout>
  );
}
